import { Component, createRef } from 'react';
import { interval, of } from 'rxjs';
import { delay, mergeMap } from 'rxjs/operators';

import { IOC_TYPES } from '../../../context/ioc.types';
import { lazyInject } from '../../../context/module';
import { Logger } from '../../../models/logger';
import { EnvironmentService } from '../../../services/environment.service';
import { MotivesService } from '../../../services/motives.service';
import { WindowService } from '../../../services/window.service';
import { get, isNaN, isNumber, parseInt, random, toInteger, toString } from '../../../utils/lodash-min';
import { reloadAfterExecute } from '../../../utils/react.utils';
import { MotiveConfig, MotiveLocationType } from '../../../utils/request.utils';
import { StringUtils } from '../../../utils/string-utils';
import { untilDestroyed } from '../../../utils/until-destroyed';
import styles from './motive.component.module.scss';
import FreestarAdSlot from '@freestar/pubfig-adslot-react-component'
import { WindowSize, WindowUtils } from '../../../utils/window.utils';
import { BrowserUtils } from '../../../ui-utils/browser-utils';


const defaultOptions = {
    showAds: true,
    className: 'width_major',
}

interface MotiveComponentState {
    showAds?: boolean;
    styles: { [key: string]: string }
}

interface MotiveComponentProps {
    children?: any;
    showAds?: boolean;
    data?;
    containerWidth?: string;
    containerHeight?: string;
    adWidth?: string;
    adHeight?: string;
    className?: 'width_major' | 'height_major' | 'width_major_md';
    location?: MotiveLocationType;
}

export class MotiveComponent extends Component<MotiveComponentProps, MotiveComponentState> {
    logger = Logger.getLogger('MotiveComponent');

    mergedOptions: MotiveComponentProps = {} as any;
    currentMotiveSlotIsEnabled = false;
    motiveType = null;
    uuid = 'x' + (StringUtils.uuid() as string).replace(/\-/g, '');
    initialized = false;
    private effectiveMotives: MotiveConfig;

    motiveLocationDisplayedAfterUserInteration = true;
    userInteractedWithPage = false;

    // Preact fix
    // @ViewChild('parent') parent: ElementRef;
    // @ViewChild('container') container: ElementRef;
    parent = createRef<HTMLDivElement>();
    container = createRef<HTMLDivElement>();

    publisher = 'xconvert-com'
    placementName;
    slotId;
    targeting = {};

    /*** This is no longer used by the shouldShowAds method */
    get meetsUserInteractionCondition() {
        return !this.motiveLocationDisplayedAfterUserInteration || (this.motiveLocationDisplayedAfterUserInteration && this.userInteractedWithPage);
    }

    get shouldShowAds() {
        return this.state.showAds && (this.currentMotiveSlotIsEnabled || false) && (this.meetsUserInteractionCondition || true);
    }

    @lazyInject(IOC_TYPES.MotivesService) private motivesService: MotivesService;
    @lazyInject(IOC_TYPES.WindowService) private windowService: WindowService;
    @lazyInject(IOC_TYPES.EnvironmentService) private environmentService: EnvironmentService;

    readonly state: Readonly<MotiveComponentState> = { showAds: this.props.showAds, styles: {} }
    static counter = 1;
    constructor(props: MotiveComponentProps) {
        super(props);
        this.slotId = 'in_content_ad_' + (MotiveComponent.counter++);

        if (this.props.location.match(/left/i)) {
            this.placementName = `xconvert_sidewall_left`
        } else if (this.props.location.match(/right/i)) {
            this.placementName = `xconvert_sidewall_right`
        } else if (this.props.location.match(/top/i) || this.props.location.match(/title/i)) {
            this.placementName = `xconvert_header`
        } else if (this.props.location.match(/bottom/i)) {
            this.placementName = `xconvert_footer`
        }
    }

    private selectValueBasedOnWindowWidth(sm, md, lg) {
        if(!BrowserUtils.hasWindow()) {
            return lg;
        }
        if (WindowUtils.getWindowSize() <= WindowSize.SM) {
            return sm
        }
        if (WindowUtils.getWindowSize() <= WindowSize.MD) {
            return md
        }
        return lg
    }

    componentDidMount() {
        this.windowService.userEngaged.subscribe({
            next: () => {
                this.userInteractedWithPage = true;
                this.updateView();
            }
        });

        this.motivesService.onEffectiveMotiveSettingsChange.pipe(untilDestroyed(this)).subscribe({
            next: (motives) => {
                this.effectiveMotives = motives;
                this.motiveLocationDisplayedAfterUserInteration = !(this.effectiveMotives.bypassMouseEvents || []).includes(this.mergedOptions.location);
                this.setUpMotiveRotation();
                this.updateView();
            },
            error: (error) => this.logger.error(error),
        });

        // if (this.container && this.container.nativeElement) {
        //     /* tslint:disable */
        //     const flags = RendererStyleFlags2.DashCase | RendererStyleFlags2.Important;
        //     // this.renderer.setStyle(this.container.nativeElement, 'margin', 'auto');
        //     // this.renderer.setStyle(this.parent.nativeElement, 'width', this.containerWidth, flags);
        //     // this.renderer.setStyle(this.parent.nativeElement, 'height', this.containerHeight, flags);
        // }

        if (BrowserUtils.hasWindow()) {
            if (this.props.location.match(/left/i)) {
                this.setState({
                    styles: { display: this.selectValueBasedOnWindowWidth('none', 'block', 'block') }
                })

            } else if (this.props.location.match(/right/i)) {
                this.setState({
                    styles: { display: this.selectValueBasedOnWindowWidth('none', 'block', 'block') }
                })
            } else if (this.props.location.match(/top/i) || this.props.location.match(/title/i) || this.props.location.match(/bottom/i)) {
                this.setState({
                    styles: {
                        display: this.selectValueBasedOnWindowWidth('block', 'block', 'block'),
                        height: this.selectValueBasedOnWindowWidth('120px', '120px', '120px'),
                        maxHeight: this.selectValueBasedOnWindowWidth('120px', '120px', '120px')
                    }
                })
            }
        }
        this.initialized = true;
    }

    setUpMotiveRotation() {
        if (this.effectiveMotives.rotateMotives) {
            const motiveRotationPeriodInSeconds = this.effectiveMotives.motiveRotationPeriodInSeconds;
            const motiveRotationRandonOffsetRangeInSeconds = this.effectiveMotives.motiveRotationRandonOffsetRangeInSeconds;
            let rand = toInteger(random(motiveRotationPeriodInSeconds, motiveRotationPeriodInSeconds + motiveRotationRandonOffsetRangeInSeconds));
            interval(rand * 1000).pipe(untilDestroyed(this)).pipe(
                mergeMap(() => {
                    if (this.state.showAds) {
                        this.setState({
                            showAds: false,
                        });
                        return of(true)
                    }
                    return of(false);
                }),
                delay(1000),
            ).subscribe((changedByTheInterval) => {
                if (changedByTheInterval) {
                    this.setState({
                        showAds: true,
                    });
                    this.updateView();
                }
            });
        }
    }

    @reloadAfterExecute()
    updateView() {
        if (this.effectiveMotives) {
            this.motiveType = this.effectiveMotives && this.effectiveMotives.externalMotiveType;
        }

        if (this.mergedOptions.showAds) {
            this.currentMotiveSlotIsEnabled = !!get(this.effectiveMotives, this.mergedOptions.location);
            if (this.currentMotiveSlotIsEnabled) {
                this.runMotives();
                this.updateWidth();
            }
        }
    }


    shouldComponentUpdate(nextProp: MotiveComponentProps) {
        return nextProp.data !== nextProp.data;
    }

    componentDidUpdate(prevProps: MotiveComponentProps) {
        if (prevProps.showAds != this.props.showAds) {
            this.setState({
                showAds: this.props.showAds,
            });
            this.updateView();
        }
    }

    private updateWidth() {
        const widthAsNumber = parseInt(this.mergedOptions.adWidth);
        if (isNumber(widthAsNumber) && !isNaN(widthAsNumber)) {
            try {
                if (window && (window.innerWidth < widthAsNumber)) {
                    this.mergedOptions.adWidth = toString(window.innerWidth) + 'px';
                }
            } catch {
                // prevent throw in SSR mode
            }
        }
    }

    runMotives() {
        if (this.motiveType === 'buysell') {
            //  <script *ngIf="motiveType == 'buysell'" async type="text/javascript" src="//cdn.carbonads.com/carbon.js?serve=CE7DC27L&placement=wwwxconvertcom" id="_carbonads_js"></script>

            const node = document.createElement('script');
            node.src = "//cdn.carbonads.com/carbon.js?serve=CE7DC27L&placement=wwwxconvertcom";
            node.type = 'text/javascript';
            node.async = true;
            node.id = this.uuid + "_carbonads_js",
                node.onload = () => {

                };
            setTimeout(() => {
                document.getElementById(this.uuid).appendChild(node);
            }, 100);
            return;
        }
        setTimeout(() => {
            try {
                const viewWidth = window && window.innerWidth;
                if (this.shouldFitToContainer()) {
                    // TODO Preact see that angular does
                    // const rect = this.containerElemRef.current.getBoundingClientRect();
                    // this.renderer.setStyle(this.containerElemRef.nativeElement, 'height', '90px');
                    // this.renderer.setStyle(this.containerElemRef.nativeElement, 'width', rect.width + 'px');
                    // this.renderer.setStyle(this.parent.nativeElement, 'width', rect.width + 'px');
                }
                (window['adsbygoogle'] = window['adsbygoogle'] || []).push({});
            } catch (e) {
                this.logger.error(e.message);
            }
        }, 400);
    }


    shouldFitToContainer() {
        return this.mergedOptions.containerWidth === '100%';
    }

    toggleGContent() {
        this.setState({
            showAds: !this.state.showAds,
        });
        this.updateView();
    }

    showGContent() {
        this.setState({
            showAds: true,
        });
        this.updateView();
    }

    getMotiveOrPlaceholder() {
        if (this.shouldShowAds) {
            return <div className={`m-container ${styles['external-motives']}`} ref={this.parent}>
                <div ref={this.container} className={`gcontainer ${styles['gcontainer']} ${styles[this.props.className]}`}>
                    {
                        this.motiveType == 'google' && <ins className={`adsbygoogle ${styles[this.props.className]}`}
                            style={{ display: 'inline-block', textAlign: 'center' }} data-ad-client={this.environmentService.env.gadId}
                            data-ad-slot={this.mergedOptions.data}></ins>
                    }
                </div>
                <div className={`gclose`}
                    style={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        fontSize: '.7rem',
                        padding: '.5rem',
                        background: '#717171',
                        borderRadius: '100%',
                        color: '#fff',
                        lineHeight: '.5rem',
                        cursor: 'pointer',
                        zIndex: 1,
                    }}
                    onClick={(e) => this.toggleGContent()}>X</div>
            </div>
        } else {
            return <div className={`m-container ${styles['m-container']} ${styles['external-motives']}`}>
                <div className={`no-m gcontainer ${styles['gcontainer']} ${styles[this.props.className]}`}>
                    <div></div>
                </div>
            </div>
        }
    }

    getFreestarMotiveOrPlaceholder() {
        // console.log(!this.placementName, !this.shouldShowAds)
        if (!this.placementName || !this.shouldShowAds) {
            return <div  className={`${(this.placementName && styles[this.placementName]) || 'empty hidden'}`}>
            </div>
        }
        return <>
            <div className={`${(this.placementName && styles[this.placementName]) || ''}`} style={{
                ...this.state.styles,
                alignItems: 'flex-start',
                display: 'flex',
                justifyContent: 'flex-start'
            }}>
                {
                <FreestarAdSlot
                    publisher={this.publisher}
                    placementName={this.placementName}
                    slotId={`${this.props.location}`}
                    targeting={this.targeting}
                    channel='custom_channel'
                    classList={[]}
                />
                }
            </div>
        </>
    }

    render() {
        this.mergedOptions = Object.assign({}, defaultOptions, this.props);
        return this.getFreestarMotiveOrPlaceholder();
    }
}
