export function reloadAfterExecute() {
    return function (target: any, propertyKey: string, descriptor: any) {
        var originalMethod = descriptor.value;
        descriptor.value = function (...args: any[]) {
            const result = originalMethod.apply(this, args);
            if (this.forceUpdate) {
                this.forceUpdate();
            } else {
                console.warn('Force update was not in context. Changes will not reflect on DOM.');
            }
            return result;
        }

        return descriptor;
    }
}

export function reloadAfterExecuteAsync() {
    return function (target: any, propertyKey: string, descriptor: any) {
        var originalMethod = descriptor.value;
        descriptor.value = async function (...args: any[]) {
            const result = await originalMethod.apply(this, args);
            if (this.forceUpdate) {
                this.forceUpdate();
            } else {
                console.warn('Force update was not in context. Changes will not reflect on DOM.');
            }
            return result;
        }

        return descriptor;
    }
}